import React, { useEffect, useState } from "react";
import "../InviteUser/inviteUser.scss";
import { useNavigate } from "react-router";
import { useFormik } from "formik";
import { FaArrowLeft, FaCheckCircle } from "react-icons/fa";
import CustomBackdrop from "../../../../../common/Loader";
import {
  getFrameDetails,
  getInviteUsers,
} from "../../../../../services/frameDetails";
import greyframe from "../../../../../assets/icons/GreyFrame.png";
import disneyFrame from "../../../../../assets/icons/Disney.png";
import brookStoneFrame from "../../../../../assets/icons/BrookStone.png";
import SSHFrame from "../../../../../assets/icons/SSH.png";
import blackframe from "../../../../../assets/icons/BlackFrame.png";
import * as Yup from "yup";
import CircularProgress from "@mui/material/CircularProgress";
import { FaAnchorCircleXmark } from "react-icons/fa6";
import { sentryLog } from "../../../../../utils/error.util";

function InviteUser() {
  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);
  const [totalFrame, setTotalFrames] = useState([]);
  const [selectedFrameId, setSelectedFrameId] = useState([]);
  const [email, setEmail] = useState("");
  const [message, setMessage] = useState("");
  const [flag, setFlag] = useState(false);
  const [isSuccess, setIsSuccess] = useState(false);
  const [isError, setIsError] = useState(false);

  const initialValues = {
    email: "",
  };

  const InviteUserSchema = Yup.object().shape({
    email: Yup.string()
      .matches(
        /^[\w.]+@\w+\.\w+(,\s*[\w.]+@\w+\.\w+)*$/,
        "It is not an email address",
      )
      .required("Please input your email address"),
    password: Yup.string().required("Please input your password"),
  });
  const getInputClasses = (fieldname) => {
    if (formik.touched[fieldname] && formik.errors[fieldname]) {
      return "is-invalid";
    }
    if (formik.touched[fieldname] && !formik.errors[fieldname]) {
      return "is-valid";
    }
    return "";
  };

  const formik = useFormik({
    initialValues,
    validationSchema: InviteUserSchema,
    onSubmit: (values) => {
      let requestData = {
        to_emails: values.email.split(",").map((email) => email.trim()),
        frame_ids: totalFrame
          .filter((frame) => frame.isFrameChecked === true)
          .map((data) => (data.isFrameChecked ? data.frameId : "")),
      };
      setFlag(false);
      if (requestData) {
        getInviteUsers(requestData).then((response) => {
          if (response) {
            setIsSuccess(true);
            sentryLog.info({ message: response.message, state: response });
            const message = response.message;
            setMessage(message);
          } else {
            sentryLog.error({ message: response.message, state: response });
            setIsError(true);
          }
        });
      }
      setFlag(true);
    },
  });

  useEffect(() => {
    getFrameDetailsByUser();
  }, []);

  /**
   * It is used to get details of frames by user loggedIn.
   */
  const getFrameDetailsByUser = () => {
    setLoading(true);
    getFrameDetails().then((response) => {
      if (response) {
        const totalFrames = response.data.map((data) => {
          return {
            frameId: data.frame_id,
            firstName: data.firstname,
            lastName: data.lastname,
            friendlyName: data.friendly_name,
            frameType: data.frame_type,
            accessStatus: data.access_status,
            isFrameChecked: false,
            isOwner: data.is_owner,
          };
        });
        setTotalFrames(totalFrames);
        sentryLog.info({ message: response.message, state: response });
        setLoading(false);
      } else {
        sentryLog.error({ message: response.message, state: response });
        setLoading(false);
      }
    });
  };

  /**
   * It is used to get user back to dashboard.
   */
  const backToDashboard = () => {
    navigate("/dashboard");
  };

  /**
   * It is used to handle checkbox of frames and images.
   * @param frameId
   */
  const handleFrameCheckboxChange = (frameId) => {
    setTotalFrames((prevImages) =>
      prevImages.map((frame) => {
        return frame.frameId === frameId
          ? { ...frame, isFrameChecked: !frame.isFrameChecked }
          : frame;
      }),
    );
    const frames = [];
    totalFrame.forEach((frame) => {
      if (frame.isFrameChecked === true) {
        frames.push(frame.frameId);
      }
    });
    setSelectedFrameId(frames);
  };

  /**
   * It is used to get back to invite.
   */
  const getBackToInvite = () => {
    setFlag(false);
  };

  return (
    <>
      <div style={{ overflowX: "hidden" }}>
        <CustomBackdrop open={loading} />
        <div className="invite-user-page">
          <div className="invite-user-header">
            <span>Invite User</span>
            <div style={{ float: "left" }}>
              <span className="cursor-pointer">
                <FaArrowLeft
                  style={{ color: "white" }}
                  onClick={backToDashboard}
                ></FaArrowLeft>
              </span>
            </div>
          </div>
          {flag === true ? (
            <>
              {isSuccess === true ? (
                <>
                  <div className="alert-message d-flex justify-content-center">
                    <FaCheckCircle
                      color="#67c23a"
                      size={25}
                      style={{ marginRight: "10px" }}
                    ></FaCheckCircle>
                    <h4 style={{ color: "#67c23a" }}>SUCCESS!</h4>
                  </div>
                  <div className="d-flex justify-content-center">
                    <h6>{message}</h6>
                  </div>
                  <div className="d-flex justify-content-center">
                    <button
                      className="invite-user-ok-btn"
                      onClick={getBackToInvite}
                    >
                      Ok
                    </button>
                  </div>
                </>
              ) : isError === true ? (
                <>
                  <div className="alert-message d-flex justify-content-center">
                    <FaAnchorCircleXmark
                      color="#f56c6c"
                      size={25}
                      style={{ marginRight: "10px" }}
                    ></FaAnchorCircleXmark>
                    <h4 style={{ color: "#67c23a" }}>Uh Oh!</h4>
                  </div>
                  <div className="d-flex justify-content-center">
                    <h6>
                      Something went wrong. Please check email address and
                      resend.
                    </h6>
                  </div>
                  <div className="d-flex justify-content-center">
                    <button
                      className="invite-user-ok-btn"
                      onClick={getBackToInvite}
                    >
                      Resend
                    </button>
                    <button
                      className="invite-user-ok-btn"
                      onClick={getBackToInvite}
                    >
                      Cancel
                    </button>
                  </div>
                </>
              ) : (
                <div className="alert-message d-flex justify-content-center">
                  <CircularProgress
                    className="text-sending"
                    color="primary"
                    size={18}
                    style={{ marginRight: "10px" }}
                  />
                  <h4 className="text-sending">SENDING</h4>
                </div>
              )}
            </>
          ) : (
            <div className="invite-user-content">
              <p
                style={{
                  padding: "1rem",
                  lineHeight: "1.7",
                  color: "rgb(102, 102, 102)",
                }}
              >
                Inviting a friend to connect to one of the Frames in your
                personal network is easy. Just enter the email and select the
                frame you want them to connect to.
              </p>
              <form onSubmit={formik.handleSubmit}>
                <div className="invite-user-form-item">
                  <div className="inviteUser-form-item-content">
                    <div
                      className="invite-user-textArea"
                      style={{ fontSize: "1.1rem" }}
                    >
                      <textarea
                        rows="4"
                        placeholder="Enter email addresses of multiple friends, separated by commas..."
                        style={{ minHeight: "38px" }}
                        className={`invite-user-textArea__content form-input-control  ${getInputClasses(
                          "email",
                        )}`}
                        onChange={(e) => setEmail(e.target.value)}
                        {...formik.getFieldProps("email")}
                      ></textarea>
                      {formik.touched.email && formik.errors.email ? (
                        <small className="error-display">
                          {formik.errors.email}
                        </small>
                      ) : null}
                    </div>
                  </div>
                </div>
                <div>
                  <div className="framebox">
                    <div className="frame" style={{ overflow: "auto" }}>
                      <div className="frameInbox">
                        {totalFrame.map((data, index) => (
                          <>
                            {data.isOwner === true ? (
                              <>
                                {data.accessStatus === 4 ||
                                data.accessStatus === 8 ? (
                                  <div
                                    key={data.frameId}
                                    className="blackFrame"
                                  >
                                    <div className="framePhoto">
                                      <img
                                        src={greyframe}
                                        alt="Logo not found"
                                      />
                                      <input
                                        id={"frame-" + index}
                                        className="img-checkbox"
                                        type="checkbox"
                                        readOnly={true}
                                      />
                                    </div>
                                    <p className="frame-style text-center">
                                      <span>{data.friendlyName}</span>
                                    </p>
                                    <p className="frame-style text-center">
                                      <span>{data.frameId}</span>
                                    </p>
                                  </div>
                                ) : (
                                  <div
                                    key={data.frameId}
                                    className="blackFrame"
                                  >
                                    <div className="framePhoto">
                                      {data.frameType.startsWith("dsn") ? (
                                        <img
                                          src={disneyFrame}
                                          alt="Logo not found"
                                        />
                                      ) : data.frameType.startsWith("brk") ? (
                                        <img
                                          src={brookStoneFrame}
                                          alt="Logo not found"
                                        />
                                      ) : data.frameType.startsWith("ssh") ? (
                                        <img
                                          src={SSHFrame}
                                          alt="Logo not found"
                                        />
                                      ) : (
                                        <img
                                          src={blackframe}
                                          alt="Logo not found"
                                        />
                                      )}
                                      <input
                                        id={"frame-" + index}
                                        className="img-checkbox"
                                        type="checkbox"
                                        checked={data.isFrameChecked}
                                        onChange={() =>
                                          handleFrameCheckboxChange(
                                            data.frameId,
                                          )
                                        }
                                      />
                                    </div>
                                    <p className="frame-style text-center">
                                      <span>{data.friendlyName}</span>
                                    </p>
                                    <p className="frame-style text-center">
                                      <span>{data.frameId}</span>
                                    </p>
                                  </div>
                                )}
                              </>
                            ) : (
                              ""
                            )}
                          </>
                        ))}
                      </div>
                    </div>
                  </div>
                  <p className="invite-user-owner-text">
                    Only the frame owner can invite others
                    <br />
                    to send photos to their frame.
                  </p>
                </div>
                <div
                  className="cursor-pointer"
                  style={{
                    textAlign: "center",
                    marginTop: "2rem",
                  }}
                >
                  <button
                    type={"submit"}
                    className={`invite-btn ${
                      totalFrame.filter((frame) => frame.isFrameChecked)
                        .length === 0
                        ? " "
                        : "dashboard-btn-primary"
                    }`}
                  >
                    Invite
                    {formik.isSubmitting}
                  </button>
                </div>
              </form>
            </div>
          )}
        </div>
      </div>
    </>
  );
}

export default InviteUser;
