import React from "react";
import "../UserGuide/userGuide.scss";
import { FaArrowLeft } from "react-icons/fa";
import { useNavigate } from "react-router";

function UserGuide() {
  const navigate = useNavigate();

  /**
   * It is used to get user back to dashboard.
   */
  const backToDashboard = () => {
    navigate("/dashboard");
  };

  return (
    <div className="contact-help-page">
      <div className="contact-help-header">
        <span>Contact/Help</span>
        <div className="contact-help-back" style={{ float: "left" }}>
          <span className="cursor-pointer">
            <FaArrowLeft
              style={{ color: "white" }}
              onClick={backToDashboard}
            ></FaArrowLeft>
          </span>
        </div>
      </div>
      <div>
        <iframe
          src="https://www.mysimplysmarthome.com/guides/photoshare/"
          style={{ height: "90vh", width: "100%" }}
        ></iframe>
      </div>
    </div>
  );
}

export default UserGuide;
