import React from "react";
import { Navigate } from "react-router-dom";
import { getCookie } from "../libs/cookies";

const ProtectedRoute = ({ children }) => {
  const isAuthenticated = () => {
    return Boolean(getCookie("accessToken"));
  };
  return <>{isAuthenticated() ? children : <Navigate to="/login" />}</>;
};

export default ProtectedRoute;
