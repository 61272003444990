import React, { useState } from "react";
import { Backdrop, Button } from "@mui/material";
import "../../style/modal.scss";
import { useFormik } from "formik";
import * as Yup from "yup";
import { Auth } from "aws-amplify";
import { showNotification } from "../../../../../common/Toast";
import { sentryLog } from "../../../../../utils/error.util";

const ChangePasswordModal = ({ open, close, setShowChangePasswordModal }) => {
  const [loading, setLoading] = useState(false);

  const initialValues = {
    oldPassword: "",
    newPassword: "",
    confirmPassword: "",
  };
  const ChangePasswordSchema = Yup.object().shape({
    oldPassword: Yup.string().required("Please enter your old password."),
    newPassword: Yup.string().required("Please enter your Password"),
    confirmPassword: Yup.string()
      .required("Please enter your Password again")
      .oneOf([Yup.ref("newPassword")], "The two Passwords don't match"),
  });

  const getInputClasses = (fieldname) => {
    if (formik.touched[fieldname] && formik.errors[fieldname]) {
      return "is-invalid";
    }
    if (formik.touched[fieldname] && !formik.errors[fieldname]) {
      return "is-valid";
    }
    return "";
  };

  const formik = useFormik({
    initialValues,
    validationSchema: ChangePasswordSchema,
    onSubmit: (values) => {
      let data = {
        PreviousPassword: values.oldPassword,
        ProposedPassword: values.newPassword,
      };
      changePassword(data);
    },
  });

  /**
   * It is used to change password.
   * @param data
   * @returns {Promise<void>}
   */
  const changePassword = async (data) => {
    setLoading(true);
    try {
      await Auth.currentAuthenticatedUser().then((user) => {
        return Auth.changePassword(
          user,
          data.PreviousPassword,
          data.ProposedPassword,
        );
      });
      setShowChangePasswordModal(false);
      showNotification("Your password has been changed successfully");
      sentryLog.info({
        message: "Your password has been changed successfully",
        state: null,
      });
      setLoading(false);
    } catch (error) {
      setShowChangePasswordModal(false);
      sentryLog.error({ message: "Oops Something went wrong!", state: error });
      setLoading(false);
    }
  };

  return (
    <>
      <Backdrop open={open} style={{ zIndex: 9999 }}>
        <div className="modal-wrapper">
          <div className="modal-container">
            <form onSubmit={formik.handleSubmit}>
              <div
                className="modal-header"
                style={{ justifyContent: "center" }}
              >
                <p className="modalHeader">Change Password</p>
              </div>
              <div className="modal-body">
                <div className="modalBody">
                  <div className="form-group">
                    <input
                      type="password"
                      name="oldPassword"
                      className={`form-input-control ${getInputClasses(
                        "oldPassword",
                      )}`}
                      placeholder={"Old Password"}
                      {...formik.getFieldProps("oldPassword")}
                    />
                  </div>
                  {formik.touched.oldPassword && formik.errors.oldPassword ? (
                    <small className="form-input-error-display-modal">
                      {formik.errors.oldPassword}
                    </small>
                  ) : null}
                  <div className="form-group" style={{ marginTop: "20px" }}>
                    <input
                      type="password"
                      name="newPassword"
                      className={`form-input-control ${getInputClasses(
                        "newPassword",
                      )}`}
                      placeholder={"New Password"}
                      {...formik.getFieldProps("newPassword")}
                    />
                  </div>
                  {formik.touched.newPassword && formik.errors.newPassword ? (
                    <small className="form-input-error-display-modal">
                      {formik.errors.newPassword}
                    </small>
                  ) : null}
                  <div className="form-group" style={{ marginTop: "20px" }}>
                    <input
                      type="password"
                      name="confirmPassword"
                      className={`form-input-control ${getInputClasses(
                        "confirmPassword",
                      )}`}
                      placeholder={"Re-enter New Password"}
                      {...formik.getFieldProps("confirmPassword")}
                    />
                  </div>
                  {formik.touched.confirmPassword &&
                  formik.errors.confirmPassword ? (
                    <small className="form-input-error-display-modal">
                      {formik.errors.confirmPassword}
                    </small>
                  ) : null}
                </div>
              </div>
              <div className="modal-footer">
                <Button className="footer cancel-btn-footer" onClick={close}>
                  Cancel
                </Button>
                <Button type="submit" className="footer ok-btn-footer">
                  OK
                  {formik.isSubmitting}
                </Button>
              </div>
            </form>
          </div>
        </div>
      </Backdrop>
    </>
  );
};

export default ChangePasswordModal;
