import * as Sentry from "@sentry/react";

export const sentryLog = {
  info: ({ message, state = null }) => {
    Sentry.captureMessage(message, { level: "info", extra: state });
  },
  error: ({ message, error }) => {
    Sentry.captureMessage(message, {
      level: "error",
      extra: error,
    });
  },
};
