import React from "react";
import { Backdrop } from "@mui/material";
import "../../../style/modal.scss";

const OwnerNotPresentModal = ({ open, close }) => {
  return (
    <Backdrop open={open} style={{ zIndex: 9999 }}>
      <div className="modal-wrapper">
        <div className="modal-container">
          <div className="modal-body">
            <div
              className="popup-body"
              style={{ fontSize: "22px", textAlign: "left" }}
            >
              <p className="has-owner" style={{ color: "#000" }}>
                Your Request has been Sent.
              </p>
              <span style={{ marginTop: "10px" }}>
                When the Frame owner approves your request, the frame will turn
                black, indicating that the request has been approved and you can
                begin to send photos to the frame.
              </span>
              <div style={{ marginTop: "10px" }}>
                <span>
                  If the request is denied, your frame will be automatically
                  removed from the app.
                </span>
              </div>
              <div style={{ marginTop: "10px" }}>
                <span>
                  While the request is pending the frame will be greyed out.
                </span>
              </div>
            </div>
          </div>
          <div className="modal-footer">
            <div className="popup-footer" onClick={close}>
              OK
            </div>
          </div>
        </div>
      </div>
    </Backdrop>
  );
};

export default OwnerNotPresentModal;
