import React, { useState } from "react";
import { Backdrop, Button } from "@mui/material";
import "../../../style/modal.scss";
import { useFormik } from "formik";
import * as Yup from "yup";
import {
  getFrameRegistration,
  hasOwnerCheckpoint,
} from "../../../../../../services/frameDetails";
import IsOwnerPresentModal from "./IsOwnerPresentModal";
import OwnerNotPresentModal from "./OwnerNotPresentModal";
import { showError, showNotification } from "../../../../../../common/Toast";
import { sentryLog } from "../../../../../../utils/error.util";
import CustomBackdrop from "../../../../../../common/Loader";

const DigitsRule = /^([0-9]*)$/;

const AddFrameModal = ({
  open,
  close,
  setShowAddFrameModal,
  getFrameDetailsByUser,
}) => {
  const [loading, setLoading] = useState(false);
  const [frameEmail, setFrameEmail] = useState("");
  const [isOwner, setIsOwner] = useState(false);
  const [showIsOwnerPresentModal, setShowIsOwnerPresentModal] = useState(false);
  const [showOwnerNotPresentModal, setShowOwnerNotPresentModal] = useState(false);

  const initialValues = {
    frameId: "",
    frameName: "",
  };
  const AddFrameSchema = Yup.object().shape({
    frameId: Yup.string()
      .matches(DigitsRule, "This Frame ID can only contain numbers")
      .required("Please enter frame ID."),
    frameName: Yup.string().required("Please enter frame name."),
  });

  const getInputClasses = (fieldname) => {
    if (formik.touched[fieldname] && formik.errors[fieldname]) {
      return "is-invalid";
    }
    if (formik.touched[fieldname] && !formik.errors[fieldname]) {
      return "is-valid";
    }
    return "";
  };

  const formik = useFormik({
    initialValues,
    validationSchema: AddFrameSchema,
    onSubmit: (values) => {
      hasOwner(values.frameId);
      let requestData = {
        frame_id: values.frameId,
        friendly_name: values.frameName,
      };
      if (requestData) {
        getFrameRegister(requestData);
      }
    },
  });

  /**
   * It is used to check whether the owner is user or not.
   * @param frameId
   */
  const hasOwner = (frameId) => {
    hasOwnerCheckpoint(frameId).then((response) => {
      if (response) {
        const isOwner = response.data.has_owner;
        setIsOwner(isOwner);
        sentryLog.info({ message: response.message, state: response });
      } else {
        sentryLog.error({ message: response.message, state: response });
      }
    });
  };

  /**
   * It is used to get frame register.
   * @param data
   */
  const getFrameRegister = (data) => {
    setLoading(true);
    getFrameRegistration(data).then((response) => {
      if (isOwner === true) {
        showNotification(response.message);
        setShowAddFrameModal(false);
        if (response && response.isSuccess === true) {
          let frameEmail = response.data.frame_email;
          setFrameEmail(frameEmail);
          openIsOwnerPresentModal();
          getFrameDetailsByUser();
          sentryLog.info({ message: response.message, state: response });
        } else {
          showError(response.message);
          sentryLog.error({ message: response.message, state: response });
        }
        setLoading(false);
      } else {
        setShowAddFrameModal(false);
        openOwnerNotPresentModal();
        getFrameDetailsByUser();
        setLoading(false);
      }
    });
  };

  /**
   * It is used to open modal of isOwner.
   */
  const openIsOwnerPresentModal = () => {
    setShowIsOwnerPresentModal(true);
  };

  /**
   * It is used to close modal of isOwner.
   */
  const closeIsOwnerPresentModal = () => {
    setShowIsOwnerPresentModal(false);
  };

  /**
   * It is used to open modal of Owner not present.
   */
  const openOwnerNotPresentModal = () => {
    setShowOwnerNotPresentModal(true);
  };

  /**
   * It is used to close modal of Owner not present.
   */
  const closeOwnerPresentModal = () => {
    setShowOwnerNotPresentModal(false);
  };

  return (
    <>
      <CustomBackdrop open={loading} />
      <Backdrop open={open} style={{ zIndex: 9999 }}>
        <div className="modal-wrapper">
          <div className="modal-container">
            <form onSubmit={formik.handleSubmit}>
              <div
                className="modal-header"
                style={{ justifyContent: "center", padding: "0px" }}
              >
                <p className="modalHeader">Add Frame</p>
              </div>
              <div className="modal-body">
                <div className="modalBody">
                  <div className="form-group">
                    <input
                      type="text"
                      name="frameId"
                      className={`form-input-control ${getInputClasses(
                        "frameId",
                      )}`}
                      placeholder={"Enter the Frame ID of your frame"}
                      {...formik.getFieldProps("frameId")}
                    />
                  </div>
                  {formik.touched.frameId && formik.errors.frameId ? (
                    <small className="form-input-error-display-modal">
                      {formik.errors.frameId}
                    </small>
                  ) : null}
                  <div className="form-group" style={{ marginTop: "20px" }}>
                    <input
                      type="text"
                      name="frameName"
                      className={`form-input-control ${getInputClasses(
                        "frameName",
                      )}`}
                      placeholder={"Name your frame"}
                      {...formik.getFieldProps("frameName")}
                    />
                  </div>
                  {formik.touched.frameName && formik.errors.frameName ? (
                    <small className="form-input-error-display-modal">
                      {formik.errors.frameName}
                    </small>
                  ) : null}
                  {/*<small className="form-input-error-display">{error}</small>*/}

                  <p className={"addText"}>
                    Your Frame ID can be found in Settings-Manage User After the
                    frame is registered you can begin to send photos from the
                    app to the frame
                  </p>
                </div>
              </div>
              <div className="modal-footer">
                <Button className="footer cancel-btn-footer" onClick={close}>
                  Cancel
                </Button>
                <Button type="submit" className="footer ok-btn-footer">
                  OK
                  {formik.isSubmitting}
                </Button>
              </div>
            </form>
          </div>
        </div>
      </Backdrop>
      <IsOwnerPresentModal
        open={showIsOwnerPresentModal}
        close={closeIsOwnerPresentModal}
        frameEmail={frameEmail}
      />
      <OwnerNotPresentModal
        open={showOwnerNotPresentModal}
        close={closeOwnerPresentModal}
      />
    </>
  );
};

export default AddFrameModal;
