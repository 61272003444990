import React from "react";
import "../auth/style/contact-help.scss";
import { FaArrowLeft } from "react-icons/fa";
import { useNavigate } from "react-router";

function ContactHelp() {
  const navigate = useNavigate();

  /**
   * It is used to get user back to log in.
   */
  const backToLogin = () => {
    navigate("/login");
  };

  return (
    <div className="contact-help-page">
      <div className="contact-help-header">
        <span>Contact/Help</span>
        <div style={{ float: "left" }}>
          <span className="cursor-pointer">
            <FaArrowLeft
              style={{ color: "white" }}
              onClick={backToLogin}
            ></FaArrowLeft>
          </span>
        </div>
      </div>
      <div>
        <iframe
          src="https://www.mysimplysmarthome.com/psf-help/"
          style={{ height: "90vh", width: "100%" }}
        ></iframe>
      </div>
    </div>
  );
}

export default ContactHelp;
