import React from "react";
import { Backdrop, CircularProgress } from "@mui/material";

const CustomBackdrop = ({ open }) => {
  return (
    <Backdrop open={open} style={{ zIndex: 9999 }}>
      <CircularProgress color="primary" />
    </Backdrop>
  );
};

export default CustomBackdrop;
