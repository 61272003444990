import React, { useEffect, useMemo, useState } from "react";
import "./style/editFrame.scss";
import { FaAngleLeft, FaAngleRight, FaArrowLeft } from "react-icons/fa";
import { useNavigate } from "react-router";
import disneyFrame from "../../../../../../assets/icons/Disney.png";
import brookStoneFrame from "../../../../../../assets/icons/BrookStone.png";
import SSHFrame from "../../../../../../assets/icons/SSH.png";
import greyframe from "../../../../../../assets/icons/GreyFrame.png";
import blackframe from "../../../../../../assets/icons/BlackFrame.png";
import {
  getFrameDetails,
  updateFrameByFrameId,
} from "../../../../../../services/frameDetails";
import { useFormik } from "formik";
import RemoveFrameModal from "../RemoveFrameModal";
import CustomBackdrop from "../../../../../../common/Loader";
import Slider from "react-slick";
import useWindowDimensions from "../../../../../../common/WindowDimension";
import { sentryLog } from "../../../../../../utils/error.util";

function EditFrame() {
  const navigate = useNavigate();
  const [totalFrame, setTotalFrame] = useState([]);
  const [editFrameName, setEditFrameName] = useState("");
  const [selectedFrameId, setSelectedFrameId] = useState(undefined);
  const [frameData, setFrameData] = useState([]);
  const [loading, setLoading] = useState(false);
  const [showRemoveFrameModal, setShowRemoveFrameModal] = useState(false);
  const dims = useWindowDimensions();

  const frames = useMemo(() => {
    const breakPoint = 135 * totalFrame.length;
    return {
      className: "slider variable-width",
      variableWidth: true,
      slidesToShow: 1,
      slidesToScroll: 1,
      infinite: false,
      speed: 500,
      display: "flex",
      initialSlide: 0,
      nextArrow: <FaAngleRight className={"slider-arrow"} />,
      prevArrow: <FaAngleLeft className={"slider-arrow"} />,
      arrows: dims.width <= breakPoint,
      dots: false,
      responsive: [
        {
          breakpoint: breakPoint,
          settings: {
            infinite: true,
            arrows: true,
          },
        },
        {
          breakpoint: 320,
          settings: {
            arrows: true,
          },
        },
      ],
    };
  }, [totalFrame, dims]);

  const initialValues = {
    editFrameName: totalFrame[0]?.friendlyName || "-",
    editFrameId: totalFrame[0]?.frameId || "-",
    editFrameEmail: totalFrame[0]?.frameEmail || "-",
  };

  useEffect(() => {
    getFrameDetailsByUser();
  }, []);

  const getInputClasses = (fieldname) => {
    if (formik.touched[fieldname] && formik.errors[fieldname]) {
      return "is-invalid";
    }
    if (formik.touched[fieldname] && !formik.errors[fieldname]) {
      return "is-valid";
    }
    return "";
  };

  const formik = useFormik({
    initialValues,
    onSubmit: (values) => {
      let friendlyName = {
        friendly_name: values.editFrameName,
      };
      updateFrameDetails(friendlyName);
    },
  });

  /**
   * It is used to get back user to dashboard.
   */
  const backToDashboard = () => {
    navigate("/dashboard");
  };

  /**
   * It is used to get details of frames by user loggedIn.
   */
  const getFrameDetailsByUser = () => {
    setLoading(true);
    getFrameDetails().then((response) => {
      if (response) {
        const totalFrames = response.data.map((data, index) => {
          return {
            frameId: data.frame_id,
            firstName: data.firstname,
            lastName: data.lastname,
            friendlyName: data.friendly_name,
            frameType: data.frame_type,
            accessStatus: data.access_status,
            frameEmail: data.frame_email,
            isFrameChecked: index === 0,
          };
        });
        setTotalFrame(totalFrames);
        setSelectedFrameId(totalFrames[0].frameId);
        formik.setFieldValue("editFrameName", totalFrames[0].friendlyName);
        formik.setFieldValue("editFrameId", totalFrames[0].frameId);
        formik.setFieldValue("editFrameEmail", totalFrames[0].frameEmail);
        setFrameData(totalFrames[0]);
        sentryLog.info({ message: response.message, state: response });
        setLoading(false);
      } else {
        sentryLog.error({ message: response.message, state: response });
        setLoading(false);
      }
    });
  };

  /**
   * It is used to update frame by frame_id.
   * @param data
   */
  const updateFrameDetails = (data) => {
    setLoading(true);
    updateFrameByFrameId(frameData.frameId, data).then((response) => {
      if (response) {
        sentryLog.info({message:response.message,state:response});
        setLoading(false);
      } else {
        sentryLog.error({ message: response.message, state: response });
      }
    });
  };

  /**
   * It is used to open remove frame modal.
   */
  const openRemoveFrameModal = () => {
    setShowRemoveFrameModal(true);
  };

  /**
   * It is used to close remove frame modal.
   */
  const closeRemoveFrameModal = () => {
    setShowRemoveFrameModal(false);
  };

  /**
   * It is used to display details of selected frame.
   * @param frameData
   */
  const handleFrameCheckboxChange = (frameData) => {
    formik.setFieldValue(
      "editFrameName",
      frameData.friendlyName || initialValues.editFrameName,
    );
    formik.setFieldValue("editFrameId", frameData.frameId || "-");
    formik.setFieldValue("editFrameEmail", frameData.frameEmail || "-");

    setFrameData({ ...frameData });
    setSelectedFrameId(frameData.frameId);
  };

  return (
    <>
      <div style={{ overflowX: "hidden" }}>
        <CustomBackdrop open={loading} />
        <div className="editFrame">
          <div className="edit-frame-header">
            <span>Edit Frame</span>
            <div style={{ float: "left" }}>
              <span className="cursor-pointer">
                <FaArrowLeft
                  style={{ color: "white" }}
                  onClick={backToDashboard}
                ></FaArrowLeft>
              </span>
            </div>
          </div>
          <div className="framebox" style={{ height: "300px" }}>
            <div className="frame">
              <div className="frameInbox">
                <Slider {...frames} error={false}>
                  {totalFrame.map((data, index) => (
                    <>
                      {data.accessStatus === 4 || data.accessStatus === 8 ? (
                        <div key={data.frameId} className="blackFrame">
                          <div className="framePhoto">
                            <img src={greyframe} alt="Logo not found" />
                            <input
                              id={"frame-radio-" + index}
                              className="img-checkbox"
                              type="radio"
                              name="frame-radio-grp"
                              checked={data.frameId === selectedFrameId}
                              onChange={() => handleFrameCheckboxChange(data)}
                            />
                          </div>
                          <p className="frame-style text-center">
                            <span>{data.friendlyName}</span>
                          </p>
                        </div>
                      ) : (
                        <div key={data.frameId} className="blackFrame">
                          <div className="framePhoto">
                            {data.frameType.startsWith("dsn") ? (
                              <img src={disneyFrame} alt="Logo not found" />
                            ) : data.frameType.startsWith("brk") ? (
                              <img src={brookStoneFrame} alt="Logo not found" />
                            ) : data.frameType.startsWith("ssh") ? (
                              <img src={SSHFrame} alt="Logo not found" />
                            ) : (
                              <img src={blackframe} alt="Logo not found" />
                            )}
                            <input
                              id={"frame-" + index}
                              className="img-checkbox"
                              type="radio"
                              name="radio"
                              checked={data.frameId === selectedFrameId}
                              onChange={() => handleFrameCheckboxChange(data)}
                            />
                          </div>
                          <p className="frame-style text-center">
                            <span>{data.friendlyName}</span>
                          </p>
                        </div>
                      )}
                    </>
                  ))}
                </Slider>
              </div>
            </div>
          </div>
          <div className="edit-form">
            <form onSubmit={formik.handleSubmit}>
              <div className="form-group">
                <div className="edit-frame-form-item">
                  <label
                    className="edit-frame-form-label"
                    style={{ width: "100px" }}
                  >
                    Frame Name :{" "}
                  </label>
                  <div
                    className="edit-frame-form-details"
                    style={{ marginLeft: "100px" }}
                  >
                    <input
                      type="text"
                      name="editFrameName"
                      className={`form-input-control ${getInputClasses(
                        "editFrameName",
                      )}`}
                      onChange={(e) => setEditFrameName(e.target.value)}
                      {...formik.getFieldProps("editFrameName")}
                    />
                  </div>
                </div>
              </div>
              <div className="form-group">
                <div className="edit-frame-form-item">
                  <label
                    className="edit-frame-form-label"
                    style={{ width: "100px" }}
                  >
                    Frame ID:{" "}
                  </label>
                  <div
                    className="edit-frame-form-details"
                    style={{ marginLeft: "100px" }}
                  >
                    <input
                      type="text"
                      name="editFrameId"
                      disabled={true}
                      readOnly={true}
                      className={`form-input-control ${getInputClasses(
                        "editFrameId",
                      )}`}
                      {...formik.getFieldProps("editFrameId")}
                    />
                  </div>
                </div>
              </div>
              {frameData.accessStatus === 4 || frameData.accessStatus === 8 ? (
                <> </>
              ) : (
                <div className="form-group">
                  <div className="edit-frame-form-item">
                    <label
                      className="edit-frame-form-label"
                      style={{ width: "100px" }}
                    >
                      Frame Email:{" "}
                    </label>
                    <div
                      className="edit-frame-form-details"
                      style={{ marginLeft: "100px" }}
                    >
                      <input
                        type="text"
                        name="editFrameEmail"
                        disabled={true}
                        readOnly={true}
                        defaultValue={
                          formik.getFieldProps("editFrameEmail").value
                        }
                        className={`form-input-control ${getInputClasses(
                          "editFrameEmail",
                        )}`}
                        {...formik.getFieldProps("editFrameEmail")}
                      />
                    </div>
                  </div>
                </div>
              )}
              <div className="d-flex justify-content-center">
                <button type="submit" className="edit-submit-form-button">
                  Done
                  {formik.isSubmitting}
                </button>
                <button
                  className="edit-remove-frame-button"
                  onClick={openRemoveFrameModal}
                >
                  Remove Frame
                </button>
              </div>
            </form>
          </div>
        </div>

        <RemoveFrameModal
          open={showRemoveFrameModal}
          close={closeRemoveFrameModal}
          friendlyName={frameData.friendlyName}
          isFrameChecked={frameData.isFrameChecked}
          frameId={frameData.frameId}
          setShowRemoveFrameModal={setShowRemoveFrameModal}
          getFrameDetailsByUser={getFrameDetailsByUser}
        ></RemoveFrameModal>
      </div>
    </>
  );
}

export default EditFrame;
