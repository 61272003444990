const awsexports = {
  aws_project_region: "ap-south-1",
  aws_cognito_identity_pool_id:
    "us-east-1:17170e87-7243-4d7a-859c-8d2a800f7887",
  aws_cognito_region: "ap-south-1",
  aws_user_pools_id: "ap-south-1_1cR3Wzged",
  aws_user_pools_web_client_id: "acu57728bldr28247pa9m1434",
  oauth: {},
  aws_cognito_username_attributes: [],
  aws_cognito_social_providers: [],
  aws_cognito_signup_attributes: ["EMAIL"],
  aws_cognito_mfa_configuration: "OFF",
  aws_cognito_mfa_types: ["SMS"],
  aws_cognito_password_protection_settings: {
    passwordPolicyMinLength: 8,
    passwordPolicyCharacters: [],
  },
  aws_cognito_verification_mechanisms: ["EMAIL"],
  awsExports: {
    REGION: "us-east-1",
    USER_POOL_ID: "us-east-1_X3PqzlR9W",
    USER_POOL_APP_CLIENT_ID: "7pn2a18cofungdoaiec2pkfkim",
  },
};

export default awsexports;
