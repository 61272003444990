import React, { useState } from "react";
import "./style/auth.scss";
import { useFormik } from "formik";
import * as Yup from "yup";
import { ref } from "yup";
import { Auth } from "aws-amplify";
import { useNavigate } from "react-router";
import "../../style/icons.scss";
import { showError, showNotification } from "../../common/Toast";
import { sentryLog } from "../../utils/error.util";

function ForgotPassword() {
  const navigate = useNavigate();
  const [email, setEmail] = useState("");
  const [verifyCode, setVerifyCode] = useState("");
  const [password, setPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");
  const [error, setError] = useState("");
  const [loading, setLoading] = useState(false);
  const [verifyEmail, setVerifyEmail] = useState(true);

  const initialValues = {
    email: "",
    verifyCode: "",
    password: "",
    confirmPassword: "",
  };

  const ForgotPasswordSchema = Yup.object().shape({
    email: Yup.string()
      .matches(
        /^\w+((-|\.|_)\w+)*\w*@\w+(-\w)?(\w*)?\.\w{2,3}(\.\w{2,3})?$/gim,
        "It is not an email address",
      )
      .required("Please enter your Email address"),
    verifyCode: Yup.string().required("Please enter your verification code"),
    password: Yup.string().required("Please enter your Password"),
    confirmPassword: Yup.string()
      .required("Please enter your Password again")
      .oneOf([ref("password")], "The two Passwords don't match"),
  });

  const formik = useFormik({
    initialValues,
    validationSchema: ForgotPasswordSchema,
    onSubmit: (values) => {
      let data = {
        userName: values.email,
        password: values.password,
      };
      if (verifyEmail) {
        verifySentCode(values.email);
      } else {
        forgotPasswordSubmit(values.email, values.verifyCode, values.password);
      }
    },
  });

  const getInputClasses = (fieldname) => {
    if (formik.touched[fieldname] && formik.errors[fieldname]) {
      return "is-invalid";
    }
    if (formik.touched[fieldname] && !formik.errors[fieldname]) {
      return "is-valid";
    }
    return "";
  };

  /**
   * It is used to verify code sent to user's email address.
   * @param userName
   * @returns {Promise<void>}
   */
  async function verifySentCode(userName) {
    if (userName) {
      try {
        await Auth.forgotPassword(userName).then((response) => {
          if (response) {
            setVerifyEmail(false);
            showNotification("Verification sent to your email address.");
            sentryLog.info({
              message: "Verification sent to your email address.",
              state: response,
            });
          }
        });
      } catch (error) {
        showError("Attempt limit exceeded, please try after some time.");
        sentryLog.error({
          message: "Attempt limit exceeded, please try after some time.",
          state: error,
        });
      }
    }
  }

  /**
   * It is used to change password to new password after user forgot password.
   * @param userName
   * @param verifyCode
   * @param newPassword
   * @returns {Promise<void>}
   */
  async function forgotPasswordSubmit(userName, verifyCode, newPassword) {
    setLoading(true);
    try {
      const data = await Auth.forgotPasswordSubmit(
        userName,
        verifyCode,
        newPassword,
      );
      setLoading(false);
      showNotification("Password changed successfully");
      sentryLog.info({
        message: "Password changed successfully.",
        state: data,
      });
      navigate("/login");
    } catch (error) {
      setLoading(false);
      showError("Email or Verification Code does not exist");
      sentryLog.error({
        message: "Email or Verification Code does not exist",
        state: error,
      });
    }
  }

  /**
   * It is used to get user back to log in.
   */
  const getBackToLogin = () => {
    navigate("/login");
  };

  return (
    <div className="box">
      <div className="forgot-password-title">
        <span>Reset Password</span>
      </div>
      <div className="forgot-password-content text-center">
        <p style={{ marginBottom: "11px" }}>
          Type in your email address to receive an email with a verification
          code to reset your password.
        </p>
        <p style={{ marginBottom: "11px" }}>
          When you have the verification code, type it in the verification code
          box and then enter your new password before tapping Confirm button
        </p>
      </div>
      <div className="forgot-password-form">
        <form onSubmit={formik.handleSubmit}>
          <div className="form-group" style={{ padding: "5px" }}>
            <input
              type="email"
              name="email"
              className={`form-input-control ${getInputClasses("email")}`}
              placeholder={"Email"}
              value={email}
              onChange={(e) => setEmail(e.target.value)}
              {...formik.getFieldProps("email")}
            />
          </div>
          {formik.touched.email && formik.errors.email ? (
            <small className="form-input-error-display">
              {formik.errors.email}
            </small>
          ) : null}
          <div
            className="forgot-password-input-content"
            style={{ padding: "5px" }}
          >
            <div className="form-group">
              <input
                type="text"
                name="code"
                className={`form-input-control ${getInputClasses(
                  "verifyCode",
                )}`}
                placeholder={"Verification Code"}
                value={verifyCode}
                onChange={(e) => setVerifyCode(e.target.value)}
                {...formik.getFieldProps("verifyCode")}
              />
            </div>
            <div>
              <button
                className="verify-code-btn"
                onClick={() => verifySentCode(formik.values.email)}
              >
                <span>Send</span>
              </button>
            </div>
          </div>
          {formik.touched.verifyCode && formik.errors.verifyCode ? (
            <small className="form-input-error-display">
              {formik.errors.verifyCode}
            </small>
          ) : null}
          <div className="form-group" style={{ padding: "5px" }}>
            <input
              type="password"
              name="password"
              className={`form-input-control ${getInputClasses("password")}`}
              placeholder={"Password"}
              value={password}
              onChange={(e) => setPassword(e.target.value)}
              {...formik.getFieldProps("password")}
            />
          </div>
          {formik.touched.password && formik.errors.password ? (
            <small className="form-input-error-display">
              {formik.errors.password}
            </small>
          ) : null}
          <small className="form-input-error-display">{error}</small>
          <div className="form-group" style={{ padding: "5px" }}>
            <input
              type="password"
              name="confirmPassword"
              className={`form-input-control ${getInputClasses(
                "confirmPassword",
              )}`}
              placeholder={"Confirm Password"}
              value={confirmPassword}
              onChange={(e) => setConfirmPassword(e.target.value)}
              {...formik.getFieldProps("confirmPassword")}
            />
          </div>
          {formik.touched.confirmPassword && formik.errors.confirmPassword ? (
            <small className="form-input-error-display">
              {formik.errors.confirmPassword}
            </small>
          ) : null}
          <small className="form-input-error-display">{error}</small>
          <div className="form-group-register-button">
            <button
              className="form-button-register-control cursor-pointer"
              style={{ marginRight: "20px" }}
              onClick={getBackToLogin}
            >
              Cancel
            </button>

            <button type={"submit"} className="form-button-register-control">
              Confirm
              {formik.isSubmitting}
            </button>
          </div>
        </form>
      </div>
    </div>
  );
}

export default ForgotPassword;
