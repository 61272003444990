import React, { useState } from "react";
import { Backdrop, Button } from "@mui/material";
import "../../style/modal.scss";
import { removeFrame } from "../../../../../services/frameDetails";

const RemoveFrameModal = ({
  open,
  close,
  friendlyName,
  setShowRemoveFrameModal,
  frameId,
  getFrameDetailsByUser,
}) => {
  const [loading, setLoading] = useState(false);

  /**
   * It is used to remove selected frame.
   */
  const removeSelectedFrame = () => {
    setLoading(true);
    removeFrame(frameId).then((response) => {
      setShowRemoveFrameModal(false);
      getFrameDetailsByUser();
    });
    setLoading(false);
  };
  return (
    <Backdrop open={open} style={{ zIndex: 9999 }}>
      <div className="modal-wrapper">
        <div className="modal-container">
          <div className="modal-body">
            <p className="popup-body">Remove {friendlyName} 's Photoframe?</p>
          </div>
          <div className="modal-footer">
            <Button
              className="footer cancel-btn-footer cursor-pointer"
              onClick={close}
            >
              Cancel
            </Button>
            <Button
              className="footer ok-btn-footer cursor-pointer"
              onClick={removeSelectedFrame}
            >
              OK
            </Button>
          </div>
        </div>
      </div>
    </Backdrop>
  );
};

export default RemoveFrameModal;
