import axios from "axios";
import { getAccessToken } from "./token";
import * as Sentry from "./axios";

const axiosIns = axios.create();

axiosIns.interceptors.request.use(
  async (config) => {
    const { headers } = config,
      accessToken = await getAccessToken();
    headers.Authorization = accessToken;
    return config;
  },
  (error) => {
    return Promise.reject(error);
  },
);

axiosIns.interceptors.response.use(
  (response) => {
    return response.data;
  },
  async (error) => {
    captureExceptionForAxios(error);
    return Promise.reject(error);
  },
);

export const request = (options) => {
  const config = {
    headers: {
      "Content-Type": "application/json",
      "x-api-key": "U76rXaPIE19UpW43ob2ow9i7P0uQAI6b8bTEdTPP",
    },
    url: options.url,
    method: options.method,
  };

  if (options.body) {
    config.data = options.body;
  }
  if (options.params) {
    config.params = options.params;
  }
  return axiosIns.request(config);

  let response;
  response = {
    status: false,
    message: "Internet Disconnected",
  };
  return response;
};

export function captureExceptionForAxios(error) {
  const { status } = JSON.parse(JSON.stringify(error));
  if (status && (status !== 200 || status !== 201)) {
    console.error("Exception Captured in API: ", error);
    Sentry.captureException(error, {
      isApiError: true,
      extra: error,
    });
  }
}

export function captureException(error, state) {
  console.error("Exception Captured: ", error);
  Sentry.captureException(error, {
    extra: { error, state },
  });
}
