import React from "react";
import { Backdrop } from "@mui/material";
import "../../../style/modal.scss";

const IsOwnerPresentModal = ({ open, close, frameEmail }) => {
  return (
    <Backdrop open={open} style={{ zIndex: 9999 }}>
      <div className="modal-wrapper">
        <div className="modal-container">
          <div className="modal-body">
            <div className="popup-body">
              <p className="has-owner">
                Your Frame Email is
                <span
                  style={{
                    color: "rgb(0, 122, 255)",
                    overflowWrap: "break-word",
                    display: "block",
                  }}
                >
                  {frameEmail}
                </span>
              </p>
              <span style={{ marginTop: "15px" }}>
                Any photos send to this Email address will be send to your
                frame.
              </span>
              <div style={{ marginTop: "15px" }}>
                <span>
                  Share your frame Email with family and friends and they can
                  also send photos to your frame.
                </span>
              </div>
            </div>
          </div>
          <div className="modal-footer">
            <div className="popup-footer" onClick={close}>
              OK
            </div>
          </div>
        </div>
      </div>
    </Backdrop>
  );
};

export default IsOwnerPresentModal;
