import React from "react";
import { Backdrop } from "@mui/material";
import "../../style/modal.scss";

const SelectFrameModal = ({ open, close }) => {
  return (
    <Backdrop open={open} style={{ zIndex: 9999 }}>
      <div className="modal-wrapper">
        <div className="modal-container">
          <div className="modal-body">
            <p>Please Select a Frame</p>
          </div>
          <div className="modal-footer">
            <div className="popup-footer" onClick={close}>
              OK
            </div>
          </div>
        </div>
      </div>
    </Backdrop>
  );
};

export default SelectFrameModal;
