import * as Sentry from "@sentry/react";
import { Replay } from "@sentry/react";
import { ExtraErrorData } from "@sentry/integrations";

Sentry.init({
  dsn: process.env.REACT_APP_SENTRY_DSN_URL,
  environment: "production",
  //Ignoring known errors to be reported in sentry
  ignoreErrors: [
    "Cannot read properties of null (reading 'model')",
    "a.apply is not a function",
    "cancelled",
  ],
  integrations: [
    new Replay({
      networkDetailAllowUrls: [
        window.location.origin,
        "webapp.photoshareframe.com",
      ],
    }),
    new ExtraErrorData(),
  ],
  // Set tracesSampleRate to 1.0 to capture 100%
  // of transactions for performance monitoring.
  tracesSampleRate: 1.0,
  normalizeDepth: 8,
  // Set `tracePropagationTargets` to control for which URLs distributed tracing should be enabled
  tracePropagationTargets: ["localhost", "webapp.photoshareframe.com"],

  // Capture Replay for 40% of all sessions,
  // plus for 100% of sessions with an error
  replaysSessionSampleRate: 0.4,
  replaysOnErrorSampleRate: 1.0,
});
