import React, { useState } from "react";
import Login from "./app/auth/Login";
import { Amplify } from "aws-amplify";
import awsExports from "./aws-exports";
import "bootstrap/dist/css/bootstrap.min.css";
import {
  BrowserRouter as Router,
  Navigate,
  Route,
  Routes,
} from "react-router-dom";
import ProtectedRoute from "./router/Protected";
import ForgotPassword from "./app/auth/Forgot-Password";
import PrivacyPolicy from "./app/auth/PrivacyPolicy";
import ContactHelp from "./app/auth/Contact-Help";
import Dashboard from "./app/pages/Projects/Dashboard";
import UserGuide from "./app/pages/Projects/Dashboard/UserGuide/userGuide";
import EditFrame from "./app/pages/Projects/Frames/Modal/EditFrame/EditFrame";
import UserInfo from "./app/pages/Projects/Dashboard/UserInfo/UserInfo";

import { ToastContainer } from "react-toastify";

import { MaterialDesignContent, SnackbarProvider } from "notistack";
import { Container, styled } from "@mui/material";
import InviteUser from "./app/pages/Projects/Dashboard/InviteUser/InviteUser";

const StyledMaterialDesignContent = styled(MaterialDesignContent)(() => ({
  "&.notistack-MuiContent-success": {
    backgroundColor: "#f0f9eb",
    borderColor: "#e1f3d8",
    color: "#67c23a",
  },
  "&.notistack-MuiContent-error": {
    backgroundColor: "#970C0C",
  },
}));

Amplify.configure({
  Auth: {
    region: awsExports.awsExports.REGION,
    userPoolId: awsExports.awsExports.USER_POOL_ID,
    userPoolWebClientId: awsExports.awsExports.USER_POOL_APP_CLIENT_ID,
    identityPoolId: awsExports.aws_cognito_identity_pool_id,
  },
  Storage: {
    AWSS3: {
      region: awsExports.awsExports.REGION,
    },
    customPrefix: {
      public: "",
    },
  },
});

function App() {
  const [isAuthenticated, setIsAuthenticated] = useState(false);

  // Implement authentication logic and update `isAuthenticated` state accordingly.

  return (
    <>
      <ToastContainer
        position="top-center"
        autoClose={5000}
        hideProgressBar={true}
        newestOnTop={true}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        pauseOnHover
        theme="light"
      />
      <SnackbarProvider
        Components={{
          success: StyledMaterialDesignContent,
          error: StyledMaterialDesignContent,
        }}
      >
        <Container
          component={"div"}
          maxWidth={false}
          style={{ overflow: "auto", maxHeight: "100vh", padding: "0" }}
        >
          <Router>
            <Routes>
              <Route exact path="/login" element={<Login />} />
              <Route
                exact
                path="/forgot-password"
                element={<ForgotPassword />}
              />
              <Route exact path="/privacy-policy" element={<PrivacyPolicy />} />
              <Route exact path="/userguide" element={<UserGuide />} />
              <Route
                exact
                path="dashboard"
                element={
                  <ProtectedRoute>
                    <Dashboard />
                  </ProtectedRoute>
                }
              />
              <Route
                exact
                path="/editFrame"
                element={
                  <ProtectedRoute>
                    <EditFrame />
                  </ProtectedRoute>
                }
              />
              <Route
                exact
                path="/inviteFriends"
                element={
                  <ProtectedRoute>
                    <InviteUser />
                  </ProtectedRoute>
                }
              />
              <Route
                exact
                path="/userInfo"
                element={
                  <ProtectedRoute>
                    <UserInfo />
                  </ProtectedRoute>
                }
              />
              <Route
                exact
                path="/contact-help"
                element={
                  <ProtectedRoute>
                    <ContactHelp />
                  </ProtectedRoute>
                }
              />
              <Route exact path="/" element={<Navigate to={"login"} />} />
            </Routes>
          </Router>
        </Container>
      </SnackbarProvider>
    </>
  );
}

export default App;
