import React, { useState } from "react";
import "../UserInfo/userInfo.scss";
import { FaArrowLeft } from "react-icons/fa";
import { useNavigate } from "react-router";
import { useFormik } from "formik";
import CustomBackdrop from "../../../../../common/Loader";
import {
  getCookie,
  setCookieWithAttributes,
} from "../../../../../libs/cookies";
import { updateProfile } from "../../../../../services/frameDetails";
import { showError, showNotification } from "../../../../../common/Toast";
import * as Yup from "yup";
import { sentryLog } from "../../../../../utils/error.util";

function UserInfo() {
  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);
  const [userFirstName, setUserFirstName] = useState("");
  const [userLastName, setUserLastName] = useState("");
  const [editData, setEditData] = useState(false);

  const initialValues = {
    userEmail: "",
    userFirstName: getCookie("firstName") || "",
    userLastName: getCookie("lastName") || "",
  };

  const UserInfoSchema = Yup.object().shape({
    userFirstName: Yup.string().required("Please enter your first name."),
    userLastName: Yup.string().required("Please enter your last name."),
  });

  const getInputClasses = (fieldname) => {
    if (formik.touched[fieldname] && formik.errors[fieldname]) {
      return "is-invalid";
    }
    if (formik.touched[fieldname] && !formik.errors[fieldname]) {
      return "is-valid";
    }
    return "";
  };

  const formik = useFormik({
    initialValues,
    validationSchema: UserInfoSchema,
    onSubmit: (values) => {
      let requestData = {
        firstname: values.userFirstName,
        lastname: values.userLastName,
      };
      if (requestData) {
        getUpdateProfile(requestData);
      }
    },
  });

  /**
   * It is used to get user back to dashboard.
   */
  const backToDashboard = () => {
    navigate("/dashboard");
  };

  /**
   * It is used to change data according to selected frame.
   */
  const handleChangeEditData = () => {
    setEditData(true);
  };

  /**
   * It is used to update user profile.
   * @param data
   */
  const getUpdateProfile = (data) => {
    setLoading(true);
    updateProfile(data).then((response) => {
      if (response) {
        setCookieWithAttributes("firstName", response.data.firstname);
        setCookieWithAttributes("lastName", response.data.lastname);
        sentryLog.info({ message: response.message, state: response });
        showNotification(response.message);
        setLoading(false);
      } else {
        sentryLog.error({ message: response.message, state: response });
        showError(response.message);
        setLoading(false);
      }
    });
  };

  return (
    <>
      <div style={{ overflowX: "hidden" }}>
        <CustomBackdrop open={loading} />
        <div className="user-info-bg">
          <div className="user-info-header">
            <span>User Info</span>
            <div style={{ float: "left" }}>
              <span className="cursor-pointer">
                <FaArrowLeft
                  style={{ color: "white" }}
                  onClick={backToDashboard}
                ></FaArrowLeft>
              </span>
            </div>
          </div>
          <div className="user-info-content">
            <div className="user-info-wrap">
              <div>
                <span className="user-info-label-title">Email:</span>
                <span className="user-info-label-title">
                  {getCookie("email")}
                </span>
              </div>
            </div>
            <form onSubmit={formik.handleSubmit}>
              <div className="user-info-form-item user-info-name-form">
                <label className="user-info-label" style={{ width: "90px" }}>
                  FirstName :
                </label>
                <div>
                  {editData ? (
                    <>
                      <input
                        type="text"
                        name="userFirstName"
                        style={{
                          border: "transparent",
                          backgroundColor: "#00b2e2",
                        }}
                        className={`${getInputClasses("userFirstName")}`}
                        onChange={(e) => setUserFirstName(e.target.value)}
                        {...formik.getFieldProps("userFirstName")}
                      />
                      {formik.touched.userFirstName &&
                      formik.errors.userFirstName ? (
                        <small className="form-input-error-display-modal">
                          {formik.errors.userFirstName}
                        </small>
                      ) : null}
                    </>
                  ) : (
                    <span onClick={handleChangeEditData}>
                      {initialValues.userFirstName}
                    </span>
                  )}
                </div>
              </div>
              <div className="user-info-form-item user-info-name-form">
                <label className="user-info-label" style={{ width: "90px" }}>
                  LastName :
                </label>
                <div>
                  {editData ? (
                    <>
                      <input
                        type="text"
                        name="userLastName"
                        className={`${getInputClasses("userLastName")}`}
                        style={{
                          border: "transparent",
                          backgroundColor: "#00b2e2",
                        }}
                        onChange={(e) => setUserLastName(e.target.value)}
                        {...formik.getFieldProps("userLastName")}
                      />
                      {formik.touched.userLastName &&
                      formik.errors.userLastName ? (
                        <small className="form-input-error-display-modal">
                          {formik.errors.userLastName}
                        </small>
                      ) : null}
                    </>
                  ) : (
                    <span onClick={handleChangeEditData}>
                      {initialValues.userLastName}
                    </span>
                  )}
                </div>
              </div>
              <div className="btn-wrap">
                <div style={{ marginBottom: "25px" }}>
                  <button type={"submit"} className="confirm-btn">
                    Confirm
                    {formik.isSubmitting}
                  </button>
                </div>
                <div style={{ marginBottom: "25px" }}>
                  <button className="cancel-btn" onClick={backToDashboard}>
                    Cancel
                  </button>
                </div>
              </div>
            </form>
          </div>
        </div>
      </div>
    </>
  );
}

export default UserInfo;
