import React, { useEffect, useMemo, useRef, useState } from "react";
import { getCookie, removeCookie } from "../../../../libs/cookies";
import { useNavigate } from "react-router";
import * as Sentry from "@sentry/react";

import { v4 as uuidv4 } from "uuid";
import { Storage } from "aws-amplify";

//Import CSS
import "./style/dashboard.scss";
import "../../../../style/common.scss";

//Import Slider CSS
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

//Import Images
import setting from "../../../../assets/icons/Setting.png";
import userguide from "../../../../assets/icons/UserGuide.png";
import blackframe from "../../../../assets/icons/BlackFrame.png";
import greyframe from "../../../../assets/icons/GreyFrame.png";
import home from "../../../../assets/icons/Home.png";
import disneyFrame from "../../../../assets/icons/Disney.png";
import brookStoneFrame from "../../../../assets/icons/BrookStone.png";
import SSHFrame from "../../../../assets/icons/SSH.png";

//Import API
import { getFrameDetails } from "../../../../services/frameDetails";
import { FaAngleLeft, FaAngleRight } from "react-icons/fa";
import { Dropdown, Tab, Tabs } from "react-bootstrap";
import CustomBackdrop from "../../../../common/Loader";
import SelectFrameModal from "./Modal/SelectFrameModal";
import { showError, showNotification } from "../../../../common/Toast";
import useWindowDimensions from "../../../../common/WindowDimension";
import AddFrameModal from "../Frames/Modal/AddFrame/AddFrameModal";
import ChangePasswordModal from "./Modal/ChangePasswordModal";
import { sentryLog } from "../../../../utils/error.util";
import EXIF from "exif-js";
import Compressor from 'compressorjs';
import heic2any from 'heic2any';

function Dashboard() {
  const navigate = useNavigate();
  const dims = useWindowDimensions();
  const sliderRef = useRef(null);
  const [totalFrame, setTotalFrames] = useState([]);
  const [uploadedImages, setUploadedImages] = useState([]);
  const [sendImages, setSendImages] = useState([]);
  const [loading, setLoading] = useState(true);
  const [frameCounter, setFrameCounter] = useState([]);
  const [selectedFrameId, setSelectedFrameId] = useState(undefined);
  const [showSelectFrameModal, setShowSelectFrameModal] = useState(false);
  const [showAddFrameModal, setShowAddFrameModal] = useState(false);
  const [showChangePasswordModal, setShowChangePasswordModal] = useState(false);

  const frames = useMemo(() => {
    const breakPoint = 135 * totalFrame.length;
    return {
      className: "slider variable-width",
      variableWidth: true,
      slidesToShow: 1,
      slidesToScroll: 1,
      infinite: false,
      speed: 500,
      display: "flex",
      initialSlide: 0,
      nextArrow: <FaAngleRight className={"slider-arrow"} />,
      prevArrow: <FaAngleLeft className={"slider-arrow"} />,
      arrows: dims.width <= breakPoint,
      dots: false,
      responsive: [
        {
          breakpoint: breakPoint,
          settings: {
            infinite: true,
            arrows: true,
          },
        },
        {
          breakpoint: 320,
          settings: {
            arrows: true,
          },
        },
      ],
    };
  }, [totalFrame, dims]);

  useEffect(() => {
    if (sliderRef?.current) {
      sliderRef.current.slickGoTo(0);
    }
  }, [totalFrame, dims]);

  /**
   * It is used for configuration of different buckets according to their frameType.
   * @type {{v2Frame: string, v1Frame: string}}
   */

  const s3BucketConfig = {
    v1Frame: "cache.image.photoshareframev1.com",
    v2Frame: "image.photoshareframev2.com",
  };

  useEffect(() => {
    getFrameDetailsByUser();
  }, []);

  /**
   * It is used to get frameType for buckets.
   * @param frameType
   * @returns {string}
   */
  function getBucketForFrameType(frameType) {
    if (frameType === "psfv1-8") {
      return s3BucketConfig.v1Frame;
    } else {
      return s3BucketConfig.v2Frame;
    }
  }

  /**
   * It is used to get details of frames by user loggedIn.
   */
  const getFrameDetailsByUser = () => {
    setLoading(true);
    getFrameDetails().then((response) => {
      if (response) {
        const totalFrames = response.data.map((data) => {
          return {
            frameId: data.frame_id,
            firstName: data.firstname,
            lastName: data.lastname,
            friendlyName: data.friendly_name,
            frameType: data.frame_type,
            accessStatus: data.access_status,
            isFrameChecked: false,
          };
        });
        setTotalFrames(totalFrames);
        sentryLog.info({ message: response.message, state: response });
        setLoading(false);
      } else {
        sentryLog.error({ message: response.message, state: response });
        setLoading(false);
      }
    });
  };

  /**
   * It is used to store image in S3 Bucket.
   * @returns {Promise<void>}
   */
  const handleStoreImage = async () => {
    const frames = totalFrame.filter((frame) => frame.isFrameChecked === true);
    let sendImage = uploadedImages.filter(
      (image) => image.isImageChecked === true,
    );
    setSendImages(sendImage);
    if (sendImage && frameCounter.length > 0) {
      for (const frame of frames) {
        const bucketName = getBucketForFrameType(frame.frameType);
        try {
          setLoading(true);
          let successCount = 0;

          for (const image of sendImage) {
            const UUID = uuidv4();
            const key = `${frame.frameId}/${getCookie("email")}/${
              frame.frameId
            }_${UUID}.${
              image.name.split(".")[image.name.split(".").length - 1]
            }`;
            const jsonKey = `${frame.frameId}/${getCookie(
              "email",
            )}/webapp-${UUID}.json`;
            let metadata = {
              user_email: getCookie("email"),
            };
            if (image.caption) {
              metadata = {
                ...metadata,
                json_file_name: jsonKey,
              };
            }

             await findOrientation(image, key).then(fileData => {
              new Compressor(fileData, {
                quality: 0.8, // Adjust the quality as needed
                async success(result) {
                  image.file = result;
                  await Storage.put(key, image.file, {
                    bucket: bucketName,
                    contentType: image.file.type,
                    metadata: metadata,
                  })
                    .then(() => {
                      setTimeout(() => {
                        sendImage.isImageChecked = false;
                      }, 2000);
                      sentryLog.info({
                        message: "Send Photo Completed",
                        state: frame,
                      });
                      frame.isFrameChecked = false;
                      uploadedImages.map((image) => (image.isImageChecked = false));
                      successCount++;
                      if(successCount === sendImage.length){
                          showNotification("Send Photo Completed.");
                        setLoading(false);
                      }
                    })
                    .catch(error => {
                      setLoading(false);
                      sentryLog.error({ message: "Error uploading image.", state: error });
                      frame.isFrameChecked = false;
                      uploadedImages.map((image) => (image.isImageChecked = false));
                      console.error('err.message', error);
                      showError("Error uploading image");
                    });

                  if (image.caption) {
                    await Storage.put(
                      jsonKey,
                      { caption: image.caption, pack_id: "" },
                      {
                        metadata: {
                          user_email: getCookie("email"),
                        },
                      },
                    );
                  }
                }
              })

            }).catch(error => {
              console.error(error);
            });
          }
        }
        catch (error) {
            setLoading(false);
            sentryLog.error({ message: "Error uploading image.", state: error });
            frame.isFrameChecked = false;
            uploadedImages.map((image) => (image.isImageChecked = false));
            showError("Error uploading image");
          console.log(error);
          }
      }
    } else {
      setShowSelectFrameModal(true);
    }
  };


  const findOrientation = async (image, key) => {
    return new Promise((resolve, reject) => {
      EXIF.getData(image.file, function() {
        const orientation = EXIF.getTag(this, "Orientation");

        const img = new Image();
        img.src = URL.createObjectURL(image.file);

        img.onload = function() {
          const canvas = document.createElement("canvas");
          const ctx = canvas.getContext("2d");
          let width = img.width;
          let height = img.height;

          if (orientation === 6 || orientation === 8) {
            canvas.width = height;
            canvas.height = width;
          } else {
            canvas.width = width;
            canvas.height = height;
          }

          switch (orientation) {
            case 2:
              ctx.transform(-1, 0, 0, 1, width, 0); // flip horizontal
              break;
            case 3:
              ctx.transform(-1, 0, 0, -1, width, height); // rotate 180
              break;
            case 4:
              ctx.transform(1, 0, 0, -1, 0, height); // flip vertical
              break;
            case 5:
              ctx.transform(0, 1, 1, 0, 0, 0); // rotate 90 and flip horizontal
              break;
            case 8:
              ctx.transform(0, 1, -1, 0, height, 0); // rotate 90
              break;
            case 7:
              ctx.transform(0, -1, -1, 0, height, width); // rotate 270 and flip horizontal
              break;
            case 6:
              ctx.transform(0, -1, 1, 0, 0, width); // rotate 270
              break;
            default:
              ctx.transform(1, 0, 0, 1, 0, 0); // no transformation
              break;
          }

          ctx.drawImage(img, 0, 0);

          canvas.toBlob((blob) => {
            const file = new File([blob], key, { type: "image/jpeg" });
            resolve(file);
          }, "image/jpeg");
        };

        img.onerror = function() {
          reject(new Error("Failed to load the image."));
        };
      });
    });
  };

// Usage example



  /**
   * It is used to upload image from device.
   * @param event
   */
  const handleImageInput = async (event) => {
    const files = Array.from(event.target.files);
    for (const file of files) {
      console.log(file);

      if (["image/heif", "image/heic"].includes(file.type)) {
        try {
          const result = await handleFileConversion(file);
          setUploadedImages((prev) => [...prev, result]);
        } catch (error) {
          console.error("Error converting file:", error);
          // Handle error as needed
        }
      } else {
        setUploadedImages((prev) =>
          [...prev, {
            image: URL.createObjectURL(file),
            file: file,
            isImageChecked: false,
            name: file.name,
            type: file.type,
          }]
        );
      }
    }
  };

  const handleFileConversion = async (selectedFile) => {
    try {
      const resultBlob = await heic2any({
        blob: selectedFile,
        toType: 'image/jpeg',
      });
      const convertedFile = new File([resultBlob], selectedFile.name.replace(/\.[^/.]+$/, ".jpg"), {
        type: 'image/jpeg',
      });
      return {
        image: URL.createObjectURL(convertedFile),
        file: convertedFile,
        isImageChecked: false,
        name: convertedFile.name,
        type: convertedFile.type,
      };
    } catch (error) {
      alert('Error converting file. Please try again.');
    }
  };
  /**
   * It is used to handle checkbox of images.
   * @param imageId
   */
  const handleImageCheckboxChange = (imageId) => {
    setUploadedImages((prevImages) =>
      prevImages.map((image, index) => {
        if (index === imageId) {
          return { ...image, isImageChecked: !image.isImageChecked };
        } else {
          return image;
        }
      }),
    );
  };

  /**
   * It is used to handle checkbox of frames.
   * @param frameId
   */
  const handleFrameCheckboxChange = (frameId) => {
    setSelectedFrameId(frameId);
    setTotalFrames((prevImages) =>
      prevImages.map((frame) => {
        return frame.frameId === frameId
          ? { ...frame, isFrameChecked: !frame.isFrameChecked }
          : frame;
      }),
    );
    const frameCounter = totalFrame.map((frame) => {
      return frame.frameId === frameId
        ? { ...frame, isFrameChecked: !frame.isFrameChecked }
        : frame;
    });
    setFrameCounter(frameCounter);
  };

  /**
   * It is used to select all images uploaded.
   */
  const handleSelectAllImages = () => {
    if (
      uploadedImages.filter((image) => image.isImageChecked).length !==
      uploadedImages.length
    ) {
      setUploadedImages((prevImages) =>
        prevImages.map((image) => {
          return { ...image, isImageChecked: true };
        }),
      );
    } else {
      setUploadedImages((prevImages) =>
        prevImages.map((image) => {
          return { ...image, isImageChecked: false };
        }),
      );
    }
  };

  /**
   * It is used to get user log out.
   */

  const getUserLogout = () => {
    removeCookie();
    Sentry.setUser(null);
    navigate("/login");
  };

  /**
   * It is used to close modal of select frame.
   */
  const closeSelectFrameModal = () => {
    setShowSelectFrameModal(false);
  };
  const openAddFrameModal = () => {
    setShowAddFrameModal(true);
  };

  const closeAddFrameModal = () => {
    setShowAddFrameModal(false);
  };

  const openEditFrame = () => {
    navigate("/editFrame");
  };
  const openUserInfo = () => {
    navigate("/userInfo");
  };
  const openChangePasswordModal = () => {
    setShowChangePasswordModal(true);
  };
  const closeChangePasswordModal = () => {
    setShowChangePasswordModal(false);
  };
  const openInviteUser = () => {
    navigate("/inviteFriends");
  };

  return (
    <>
      <div style={{ overflowX: "hidden" }}>
        <CustomBackdrop open={loading} />
        <div className="selectFrame">
          <div className="page-top">
            <div
              title="Double-click to Check 100 photos."
              className="header text-center"
            >
              Select frame to send photos to
            </div>
            <div className="setting">
              <Dropdown drop="down">
                <Dropdown.Toggle
                  style={{ backgroundColor: "transparent", border: "none" }}
                >
                  <img
                    className="img cursor-pointer"
                    src={setting}
                    alt="Logo not found"
                  />
                </Dropdown.Toggle>
                <Dropdown.Menu>
                  <Dropdown.Item onClick={openAddFrameModal}>
                    Add Frame
                  </Dropdown.Item>
                  <Dropdown.Item onClick={openEditFrame}>
                    Edit Frame
                  </Dropdown.Item>
                  <Dropdown.Item onClick={openInviteUser}>
                    Invite Friends
                  </Dropdown.Item>
                  <Dropdown.Item onClick={openUserInfo}>
                    User Info
                  </Dropdown.Item>
                  <Dropdown.Item onClick={openChangePasswordModal}>
                    Change Password
                  </Dropdown.Item>
                  <Dropdown.Item onClick={getUserLogout}>Logout</Dropdown.Item>
                </Dropdown.Menu>
              </Dropdown>
              {/*</div>*/}
            </div>
            <div className="userGuideBtn">
              <a href={"/userguide"}>
                <img className="img" src={userguide} alt="Logo not found"></img>
              </a>
            </div>
            <div className="framebox">
              <div className="frame">
                <div className="frameInbox">
                  <Slider ref={sliderRef} {...frames} error={false}>
                    {totalFrame.map((data, index) => (
                      <>
                        {data.accessStatus === 4 || data.accessStatus === 8 ? (
                          <div key={data.frameId} className="blackFrame">
                            <p className="frame-style text-center">
                              <span>waiting approval</span>
                            </p>
                            <div className="framePhoto">
                              <img src={greyframe} alt="Logo not found" />
                              <input
                                id={"frame-" + index}
                                className="img-checkbox"
                                type="checkbox"
                                readOnly={true}
                              />
                            </div>
                            <p className="frame-style text-center">
                              <span>{data.friendlyName}</span>
                            </p>
                          </div>
                        ) : (
                          <div key={data.frameId} className="blackFrame">
                            <div className="framePhoto">
                              {data.frameType.startsWith("dsn") ? (
                                <img src={disneyFrame} alt="Logo not found" />
                              ) : data.frameType.startsWith("brk") ? (
                                <img
                                  src={brookStoneFrame}
                                  alt="Logo not found"
                                />
                              ) : data.frameType.startsWith("ssh") ? (
                                <img src={SSHFrame} alt="Logo not found" />
                              ) : (
                                <img src={blackframe} alt="Logo not found" />
                              )}
                              <input
                                id={"frame-" + index}
                                className="img-checkbox"
                                type="checkbox"
                                checked={data.isFrameChecked}
                                onChange={() =>
                                  handleFrameCheckboxChange(data.frameId)
                                }
                              />
                            </div>
                            <p className="frame-style text-center">
                              <span>{data.friendlyName}</span>
                            </p>
                          </div>
                        )}
                      </>
                    ))}
                  </Slider>
                </div>
              </div>
            </div>
          </div>
          <div className="buttonArea">
            <input
              type="file"
              multiple
              accept="image/*,.heic,.heif"
              id="fileInput"
              style={{ display: "none" }}
              onChange={handleImageInput}
            />
            <button
              className="dashboard-btn dashboard-btn-primary dashboard-btn-size cursor-pointer"
              style={{
                borderRadius: "50px",
                padding: "15px 30px",
                fontFamily: "sans-serif",
              }}
              onClick={() => document.getElementById("fileInput").click()}
            >
              <span>Add Photos</span>
            </button>

            <button
              className={`dashboard-btn dashboard-btn-size cursor-pointer ${
                uploadedImages.filter((image) => image.isImageChecked)
                  .length === 0
                  ? ""
                  : "dashboard-btn-primary"
              }`}
              disabled={
                uploadedImages.filter((image) => image.isImageChecked)
                  .length === 0
              }
              style={{
                marginLeft: " 0.6rem",
                borderRadius: "50px",
                padding: "15px 30px",
                fontFamily: "sans-serif",
              }}
              onClick={handleStoreImage}
            >
              <span>
                Send
                {uploadedImages.filter((image) => image.isImageChecked)
                  .length === 0
                  ? ""
                  : uploadedImages.filter((image) => image.isImageChecked)
                    .length}
              </span>
            </button>
          </div>
          <div className="buttonArea-mobile">
            <input
              type="file"
              multiple
              accept="image/*,.heic,.heif"
              id="fileInput"
              style={{ display: "none" }}
              onChange={handleImageInput}
            />

            <button
              className="dashboard-btn-mobile dashboard-btn-primary"
              onClick={() => document.getElementById("fileInput").click()}
            >
              <span>Add Photos</span>
            </button>

            <button
              className={`dashboard-btn-mobile cursor-pointer ${
                uploadedImages.filter((image) => image.isImageChecked)
                  .length === 0
                  ? " "
                  : "dashboard-btn-primary"
              }`}
              disabled={
                uploadedImages.filter((image) => image.isImageChecked)
                  .length === 0
              }
              onClick={handleStoreImage}
            >
              <span>
                Send
                {uploadedImages.filter((image) => image.isImageChecked)
                  .length === 0
                  ? ""
                  : uploadedImages.filter((image) => image.isImageChecked)
                    .length}
              </span>
            </button>
          </div>
          <div className="tabs-top">
            <div className="tabs-header">
              <div className="tabs-nav">
                <div className="tabs-nav-img">
                  <Tabs>
                    <Tab title={<img src={home} alt="Image not found" />}></Tab>
                  </Tabs>
                </div>
              </div>
              <div className="row">
                <div className="col-2"></div>
                <div className="col-8">
                  <p className="dashboard-title">
                    Select photos to send to frame (
                    {
                      uploadedImages.filter((image) => image.isImageChecked)
                        .length
                    }
                    /50)
                  </p>
                </div>
                <div className="col-2">
                  <button
                    className={`dashboard-btn dashboard-btn-size cursor-pointer dashboard-btn-selectAll ${
                      uploadedImages.filter((image) => image.isImageChecked)
                        .length === 0
                        ? " "
                        : "dashboard-btn-primary"
                    }`}
                    onClick={handleSelectAllImages}
                  >
                    <span>Select All</span>
                  </button>
                </div>
              </div>
              <div style={{ marginTop: "10px" }}>
                <div className="row">
                  <div
                    className="col image-list"
                    style={{ marginLeft: "10px" }}
                  >
                    {uploadedImages.map((imageUrl, index) => (
                      <div className={"image-container"}>
                        <img
                          key={index}
                          src={imageUrl.image}
                          alt={`Image ${index}`}
                          className="dashboard-img"
                        />
                        <input
                          type={"checkbox"}
                          id={`${imageUrl.id}`}
                          checked={imageUrl.isImageChecked}
                          className="dashboard-img-checkbox"
                          onChange={() => handleImageCheckboxChange(index)}
                        />
                      </div>
                    ))}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <SelectFrameModal
        open={showSelectFrameModal}
        close={closeSelectFrameModal}
      />

      <AddFrameModal
        open={showAddFrameModal}
        close={closeAddFrameModal}
        setShowAddFrameModal={setShowAddFrameModal}
        getFrameDetailsByUser={getFrameDetailsByUser}
      />

      <ChangePasswordModal
        open={showChangePasswordModal}
        close={closeChangePasswordModal}
        setShowChangePasswordModal={setShowChangePasswordModal}
      />
    </>
  );
}

export default Dashboard;
