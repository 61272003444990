import React, { useState } from "react";
import "./style/auth.scss";
import { useFormik } from "formik";
import * as Yup from "yup";
import logo from "../../assets/images/Logo.png";
import { useNavigate } from "react-router";
import { Auth } from "aws-amplify";
import { removeCookie, setCookieWithAttributes } from "../../libs/cookies";
import "../../style/icons.scss";
import CircularProgress from "@mui/material/CircularProgress";
import * as Sentry from "@sentry/react";
import { sentryLog } from "../../utils/error.util";

function Login() {
  const navigate = useNavigate();

  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState("");

  const initialValues = {
    email: "",
    password: "",
  };

  const LoginSchema = Yup.object().shape({
    email: Yup.string()
      .matches(
        /^\w+((-|\.|_)\w+)*\w*@\w+(-\w)?(\w*)?\.\w{2,3}(\.\w{2,3})?$/gim,
        "It is not an email address",
      )
      .required("Please input your email address"),
    password: Yup.string().required("Please input your password"),
  });

  const formik = useFormik({
    initialValues,
    validationSchema: LoginSchema,
    onSubmit: (values) => {},
  });

  const getInputClasses = (fieldname) => {
    if (formik.touched[fieldname] && formik.errors[fieldname]) {
      return "is-invalid";
    }
    return "";
    if (formik.touched[fieldname] && !formik.errors[fieldname]) {
      return "is-valid";
    }
  };

  /**
   * It is used to get user logged in.
   * @returns {Promise<void>}
   */
  const getUserLoggedIn = async () => {
    setLoading(true);
    try {
      await Auth.signIn(formik.values.email, formik.values.password).then(
        (response) => {
          if (response) {
            setLoading(false);
            Auth.currentUserInfo().then(async (response) => {
              const userInfo = await Auth.currentUserInfo();
              const email = userInfo.attributes.email;
              const firstName = userInfo.attributes["custom:firstname"];
              const lastName = userInfo.attributes["custom:lastname"];
              Sentry.setUser({
                email: email,
                name: `${firstName} ${lastName}`,
              });
              if (email) {
                setCookieWithAttributes("email", email);
                setCookieWithAttributes("firstName", firstName);
                setCookieWithAttributes("lastName", lastName);
              }
            });
            const accessToken = response?.signInUserSession?.idToken?.jwtToken;
            if (accessToken) {
              setCookieWithAttributes("accessToken", accessToken);
              sentryLog.info({
                message: response.message,
                state: response,
              });
              navigate("/dashboard");
            } else {
              removeCookie();
              sentryLog.error({
                message: error,
                state: error,
              });
              console.log("Error", error);
              Sentry.setUser(null);
              navigate("/login");
            }
          }
        },
      );
    } catch (error) {
      setLoading(false);
      setError(error.message);
      sentryLog.error({ message: error.message, state: error });
      removeCookie();
      navigate("/login");
    }
  };

  return (
    <div className="box">
      <div className="header text-center">
        <img className="img-logo" src={logo} alt="Logo not found" />
      </div>
      <div className="form">
        <form onSubmit={formik.handleSubmit}>
          <div className="form-group">
            <input
              type="email"
              name="email"
              className={`form-input-control ${getInputClasses("email")}`}
              placeholder={"Email"}
              value={email}
              onChange={(e) => setEmail(e.target.value)}
              {...formik.getFieldProps("email")}
            />
          </div>
          {formik.touched.email && formik.errors.email ? (
            <small className="form-input-error-display">
              {formik.errors.email}
            </small>
          ) : null}
          <div className="form-group" style={{ marginTop: "20px" }}>
            <div className="d-flex">
              <input
                type="password"
                name="password"
                className={`form-input-control ${getInputClasses("password")}`}
                placeholder="Password"
                value={password}
                onChange={(e) => setPassword(e.target.value)}
                {...formik.getFieldProps("password")}
              />
            </div>
          </div>
          {formik.touched.password && formik.errors.password ? (
            <small className="form-input-error-display">
              {formik.errors.password}
            </small>
          ) : null}
          <small className="form-input-error-display">{error}</small>
          <div className="form-group-button">
            <button
              type={"submit"}
              className="form-button-control cursor-pointer"
              disabled={
                formik.isSubmitting ||
                !formik.values.email ||
                !formik.values.password ||
                loading
              }
              onClick={getUserLoggedIn}
            >
              {loading ? (
                <CircularProgress
                  color="inherit"
                  size={18}
                  style={{ marginRight: "10px" }}
                />
              ) : null}
              Log in
            </button>
          </div>
          <div className="form-link text-center">
            {/*<p>*/}
            {/*  <a*/}
            {/*    className="form-footer cursor-pointer text-center"*/}
            {/*    href={"/register"}*/}
            {/*  >*/}
            {/*    Create New Account*/}
            {/*  </a>*/}
            {/*</p>*/}
            <p>
              <a
                className="form-footer cursor-pointer text-center"
                href={"/forgot-password"}
              >
                Forgot Password
              </a>
            </p>
            <p>
              <a
                className="form-footer cursor-pointer text-center"
                href={"/contact-help"}
              >
                Contact/Help
              </a>
            </p>
            <p>
              <a
                className="form-footer cursor-pointer text-center"
                href={"/privacy-policy"}
              >
                Privacy Policy and Terms of Service
              </a>
            </p>
          </div>
        </form>
      </div>
    </div>
  );
}

export default Login;
